import React from "react";
import { Footer, Header } from "../../layout";
import styles from "./styles/JefeDepartamentoPage.module.css";
import { Menu, MenuOption, useMenu } from "../../../reutilizable/Menu";
import Search from "../../../reutilizable/Search";
import SplitView from "../../../reutilizable/SplitView";
import CartaDesempeñoDocente from "../components/CartaDesempeñoDocente";
import { useAuthStore } from "../../../store/useAuthStore";
import EvaluationPage from "../../evaluarestudiante/pages/EvaluationPage";

const data = [
  {
    id: 1,
    nombre: "Maria",
    apellido: "Gonzalez",
    email: "",
    telefono: "",
  },
  {
    id: 2,
    nombre: "Juan",
    apellido: "Perez",
    email: "",
    telefono: "",
  },
  {
    id: 3,
    nombre: "Pedro",
    apellido: "Perez",
    email: "",
    telefono: "",
  },
  {
    id: 4,
    nombre: "Alejandro Sebastian",
    apellido: "Lopez de la Cruz",
    email: "",
    telefono: "",
  },
];
export const JefeDepartamentoPage = () => {
  const accessToken = useAuthStore((state) => state.accessToken);
  const userInfo = useAuthStore((state) => state.userInfo);
  const menu = useMenu(
    [
      {
        label: "Desempeño docente",
        element: (
          <>
            
            <CartaDesempeñoDocente />
          </>
        ),
      },
      {
        label: "Evaluación de estudiantes",
        element: <EvaluationPage/>,
      },
      {
        label: "Carga horaria",
        element: <p>Oficio de carga horaria</p>,
      },
      {
        label: "Cumplimiento de plan de trabajo",
        element: <p>Oficio de cumplimiento de plan de trabajo</p>,
      },{
        label: "Validación de plan de trabajo",
        element: <p>Oficio de validación de plan de trabajo</p>,
      }
    ],
    {
      selectedValue: "Desempeño docente",
      isVertical: false,
    }
  );
  console.log("Token:", accessToken);
  console.log("User Info:", userInfo);
  return (
    <div className={styles.jefe_departamento_page_container}>
      <Header></Header>
      {/* <div>JefeDepartamentoPage</div> */}

      <Menu menu={menu} />

      {/* <SplitView
        sidebarContent={
          <>
            <Menu menu={menu} />
          </>
        }
        mainContent={menu.element}
      /> */}
      {menu.element}

      {/* {data.map((item) => (
        <div key={item.id}>
          {item.nombre} {item.apellido}
        </div>
      ))} */}
      <Footer></Footer>
    </div>
  );
};
