import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styles from './styles/ReporteActaEvaluaciones.module.css'
import AsideInstrucciones from './AsideInstrucciones'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrush, faSave } from '@fortawesome/free-solid-svg-icons'

const ReporteActaEvaluaciones = () => {
  const { handleSubmit, reset, register, watch } = useForm({
    defaultValues: {
      horafecha: '',
      diafecha: '',
      mesfecha: '',
      aniofecha: '',
      lugarreunion: '',
      horaterminacion: ''
    }
  });

  const [error, setError] = useState('');
  const [isActivoPeriodoFecha, setIsActivoPeriodoFecha] = useState(false);

  const handleClickLimpiar = () => reset();

  const handleSubmitActaEvaluaciones = (data) => {
    const camposVacios = Object.values(data).some(value => value === '');
    /* if (camposVacios) {
      setError('Todos los campos son obligatorios');
      return;
    } */
    console.log(data)
  }

  const periodoFecha = watch('tipoFechaImpresion');
  useEffect(() => {
    const activoPeriodoFecha = periodoFecha === 'periodo';
    if (activoPeriodoFecha) {
      setIsActivoPeriodoFecha(true);
    } else {
      setIsActivoPeriodoFecha(false);
    }
  }, [isActivoPeriodoFecha, periodoFecha])


  return (
    <div className={styles.container}>
      <AsideInstrucciones />

      <div className={styles.container_content}>
        <div>
          <h2 className={styles.titulo}>Imprimir acta de evaluaciones</h2>
        </div>

        <div className={styles.parafo_info}>
          <form action="" onSubmit={handleSubmit(handleSubmitActaEvaluaciones)}>
            <div>
              <p className={styles.titulo_parrafo}>
                <input
                  placeholder='2a.'
                  className={styles.input_text_parrafo}
                />
                Acta de la comisión especial dictaminadora. Administración general.
              </p>

              <p className={styles.parrafo_info}>
                Siendo las
                <input
                  type="text"
                  name="horafecha"
                  {...register('horafecha')}
                  id="horafecha"
                  placeholder='12:00'
                  className={`${styles.mIpt} ${styles.input_hora}`}
                /> hrs. del
                <input
                  type="text"
                  name="diafecha"
                  {...register('diafecha')}
                  id="diafecha"
                  placeholder='15'
                  className={`${styles.mIpt} ${styles.input_hora}`}
                />de
                <input
                  type="text"
                  name="mesfecha"
                  {...register('mesfecha')}
                  id="mesfecha"
                  placeholder='julio'
                  className={`${styles.mIpt} ${styles.input_hora}`}
                />de
                <input
                  type="text"
                  name="aniofecha"
                  {...register('aniofecha')}
                  id="aniofecha"
                  placeholder='2024'
                  className={`${styles.mIpt} ${styles.input_hora}`}
                />, en
                <input
                  type="text"
                  name="lugarreunion"
                  {...register('lugarreunion')}
                  id="lugarreunion"
                  placeholder='<la salsa
                  de juntas>' className={`${styles.mIpt}`}
                />,
                Administración General, se reunieron miembros de la Comision Especial Dictaminadora de Administración General
                del Programa de Estímulos del Departamento Docente Promoción 2024-2025, para la evaluación de expedientes.
              </p>

              <p>
                La reunión se dio por terminada a las
                <input
                  type="text"
                  name="horaterminacion"
                  {...register('horaterminacion')}
                  id="horaterminacion"
                  placeholder='12:00'
                  className={`${styles.mIpt} ${styles.input_hora}`}
                />hrs. del presente
              </p>
            </div>

            <div className={styles.container_opciones}>
              <p>Hay dos opciones para imprimir el reporte de los expedientes evaluados:</p>

              <div>
                <div className={styles.input_radio}>
                  <input 
                    type='radio'
                    id='hoy'
                    value='hoy'
                    {...register('tipoFechaImpresion')}
                    className={styles.input_radio}
                  />
                  <label htmlFor='hoy'>De hoy</label>
                </div>
                <div className={styles.input_radio}>
                  <input 
                    type='radio'
                    id='periodo'
                    value='periodo'
                    {...register('tipoFechaImpresion')}
                  />
                  <label htmlFor='periodo'>De un período entre dos fechas</label>
                </div>

                <div>
                  {isActivoPeriodoFecha && (
                    <div className={styles.container_input_fechas}>
                      <div>
                        <label htmlFor="fechaDesde">Desde:</label>
                        <input type="date" name="fechaDesde" id="fechaDesde" {...register('fechaDesde')}/>
                      </div>
                      <div>
                        <label htmlFor="fechaHasta">Hasta:</label>
                        <input type="date" name="fechaHasta" id="fechaHasta" {...register('fechaHasta')}/>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              {error && (<p className={styles.alerta}>{error}</p>)}
              <div className={styles.container_buttons}>
                <button
                  type="button"
                  placeholder='Limpiar'
                  onClick={handleClickLimpiar}
                >
                    Limpiar <FontAwesomeIcon icon={faBrush} color='cyan'/>
                </button>
                <button
                  type="submit"
                  placeholder='Imprimir'
                  value='Imprimir'
                >
                  Imprimir <FontAwesomeIcon icon={faSave} color='cyan'/>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ReporteActaEvaluaciones
