import React from "react";
import { Menu, useMenu } from "../../../reutilizable/Menu";
import styles from "./visorDocumentos.module.css";
import { SubirDocumento } from "./SubirDocumento";
import { BarraBusqueda } from "../../../reutilizable/BarraBusqueda";
import { DatosParticipante } from "./DatosParticipante";
import { Requisitos } from "./Requisitos";

export const VisorDocumentos = () => {
  const menu = useMenu(
    [
      {
        label: "Subir documentos",
        element: <SubirDocumento />,
      },
      {
        label: "Datos participante",
        element: <DatosParticipante />,
      },
      {
        label: "Requisitos",
        element: <Requisitos />,
      },
    ],
    {
      customClass: {
        menu: styles.menuContainer,
        menuOption: styles.menuOption,
        subMenuOption: styles.subMenuOption,
        selected: styles.selected,
      },
    }
  );

  return (
    <div className={styles.container}>
      <BarraBusqueda />
      <Menu menu={menu} />
      <div className={styles.content}>{menu.element}</div>
    </div>
  );
};
