import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { faBrush, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles/ReporteActaAcuerdos.module.css'
import AsideInstrucciones from './AsideInstrucciones';

const ReporteActaAcuerdos = () => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      hora_fecha: '',
      dia_fecha: '',
      mes_fecha: '',
      anio_fecha: '',
      lugar_reunion: '',
      razon: '',
      hora_terminacion: ''
    }
  })
  
  const [error, setError] = useState('')
  const [miembros, _] = useState([
    {
      id: 1,
      nombre: 'dra. elba patricia alatorre rojo',
      cargo: 'presidente'
    },
    {
      id: 2,
      nombre: 'mtra. katia ariadna morales vega',
      cargo: 'secretario'
    },
    {
      id: 3,
      nombre: 'dra. ilce valeria roman fernandez',
      cargo: 'vocal'
    },
    {
      id: 4,
      nombre: 'dr. rogelio martinez cardenas',
      cargo: 'vocal'
    },
    {
      id: 5,
      nombre: 'dra. maria elena sandoval pinto',
      cargo: 'vocal'
    },
    {
      id: 6,
      nombre: 'dr. jose juan pablo rojas ramirez',
      cargo: 'vocal'
    },
    {
      id: 7,
      nombre: 'dr. ismael loza vega',
      cargo: 'vocal'
    },
    {
      id: 8,
      nombre: 'dra. Miriam bocardo gaspar',
      cargo: 'vocal'
    },
  ]);

  const handleClickLimpiar = () => reset()

  const handleClickCancelar = () => {
    console.log('cancelando')
  }

  const handleSubmitActaAcuerdos = (data) => {
    const camposVacios = Object.values(data).some(value => value === '');
    if (camposVacios) {
      setError('Todos los campos son obligatorios');
      return;
    }
    console.log(data)
  }

  return (
    <div className={styles.container}>
      <AsideInstrucciones />

      <div className={styles.container_content}>
        <div>
          <h2 className={styles.titulo}>Imprimir acta de acuerdos</h2>
        </div>

        <div className={styles.parafo_info}>
          <form action="" onSubmit={handleSubmit(handleSubmitActaAcuerdos)}>
            <div>
              <p className={styles.titulo_parrafo}>
                <input 
                  placeholder='1a.' 
                  defaultValue={'1a.'}
                  className={styles.input_text_parrafo}
                />
                Acta de la comisión especial dictaminadora. Administración general.
              </p>

              <p className={styles.parrafo_info}>
                Siendo las 
                <input 
                  type="text"
                  name="hora_fecha"
                  {...register('hora_fecha')}
                  id="hora_fecha"
                  placeholder='12:00'
                  className={`${styles.mIpt} ${styles.input_hora}`}
                /> hrs. del 
                <input
                  type="text"
                  name="dia_fecha"
                  {...register('dia_fecha')}
                  id="dia_fecha"
                  placeholder='15'
                  className={`${styles.mIpt} ${styles.input_hora}`}
                />de 
                <input
                  type="text"
                  name="mes_fecha"
                  {...register('mes_fecha')}
                  id="mes_fecha"
                  placeholder='julio'
                  className={`${styles.mIpt} ${styles.input_hora}`}
                />de 
                <input
                  type="text"
                  name="anio_fecha"
                  {...register('anio_fecha')}
                  id="anio_fecha"
                  placeholder='2024'
                  className={`${styles.mIpt} ${styles.input_hora}`}
                />, en 
                <input
                  type="text"
                  name="lugar_reunion"
                  {...register('lugar_reunion')}
                  id="lugar_reunion"
                  placeholder='<la salsa
                  de juntas>' className={`${styles.mIpt}`}
                />,
                Administración General, se reunieron miembros de la Comision Especial Dictaminadora de Administración General
                del Programa de Estímulos del Departamento Docente Promoción 2024-2025, con la finalidad de:
                <input 
                  type="text"
                  name="razon"
                  {...register('razon')}
                  id="razon"
                  placeholder='<razón>'
                  className={`${styles.mIpt}`}
                />
              </p>
            </div>

            <div className={styles.container_list_miembros}>
              <p>Los miembros de la comisión son:</p>
              <ul className={styles.ul_miembros}>
                {miembros.map(miembro => (
                  <li key={miembro.id} className={styles.list_item}>
                    {miembro.nombre} <span className={styles.span_list_item}>({miembro.cargo})</span>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <textarea
                name="text_doc"
                {...register('text_doc')}
                id="text"
                placeholder='<Área para texto>'
              ></textarea>
            </div>

            <div>
              <p>
                La reunión se dio por terminada a las
                <input 
                  type="text"
                  name="hora_terminacion"
                  {...register('hora_terminacion')}
                  id="hora_terminacion"
                  placeholder='12:00'
                  className={`${styles.mIpt} ${styles.input_hora}`}
                />hrs. del presente   
              </p>
              
              { error && ( <p className={styles.alerta}>{error}</p>)}

              <div className={styles.container_buttons}>
                  <button 
                    type="button"
                    placeholder='Limpiar'
                    onClick={handleClickLimpiar}
                  >
                    Limpiar <FontAwesomeIcon icon={faBrush} color='cyan'/>
                  </button>
                  <button
                    type="submit"
                    placeholder='Imprimir'
                    value='Imprimir'
                  >
                    Imprimir <FontAwesomeIcon icon={faSave} color='cyan'/>
                  </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ReporteActaAcuerdos
