import React, { useState } from "react";
import { Footer, Header } from "../../layout";
import styles from "./styles/AdminisstracionPage.module.css";
import { Menu, MenuOption, useMenu } from "../../../reutilizable/Menu";
import { GenerarInconformidad } from "../components/GenerarInconformidad";
import { Evaluar } from "../components/Evaluar";
import { BuscarUsuario } from "../../inconformidad/components";
import { InconformidadPage } from "../../inconformidad";
import { useAuthStore } from "../../../store/useAuthStore";
import { ActivarInactivarSolicitudComponent } from "../components/ActivarInactivarSolicitudComponent";
import { Instrucciones } from "../../lista-miembros/components/Instrucciones";
import { ReportesPage } from "../../reportes";
import { ActaDeAcuerdosComponent } from "../components";
import ReporteResultadosEvaluacion from "../../reportes/components/ReporteResultadosEvaluacion";
import AsideInstrucciones from "../../reportes/components/AsideInstrucciones";
import ReporteActaAcuerdos from "../../reportes/components/ReporteActaAcuerdos";
import ReporteActaEvaluaciones from "../../reportes/components/ReporteActaEvaluaciones";
import ReporteDictamenNoParticipantes from "../../reportes/components/ReporteDictamenNoParticipantes";
import ReporteDictamenFinal from "../../reportes/components/ReporteDictamenFinal";

export const AdministracionPage = () => {
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  const accessToken = useAuthStore((state) => state.accessToken);
  const refreshToken = useAuthStore((state) => state.refreshToken);
  const userInfo = useAuthStore((state) => state.userInfo);

  const handleVaciadosExcelClick = () => {
    alert("Descargando vaciados excel...");
  };

  const handleActaDeAcuerdosClick = () => {
    alert("Descargando acta de acuerdos...");
  };

  const menu = useMenu(
    [
      {
        label: "Instrucciones",
        element: (
          <>
            <Instrucciones />,
          </>
        ),
      },
      {
        label: "Inconformidad",
        element: <InconformidadPage />,
      },
      {
        label: "Evaluación",
        element: <Evaluar />,
      },
      {
        label: "Reportes",
        children: [
          {
            label: "Imprimir acta de acuerdos",
            element: <ReporteActaAcuerdos />,
          },
          {
            label: "Imprimir acta de evaluaciones",
            element: <ReporteActaEvaluaciones />,
          },
          {
            label: "Tabla de resultados de evaluacion",
            element: <ReporteResultadosEvaluacion/>,
          },
          {
            label: "Dictamen de no participante",
            element: <ReporteDictamenNoParticipantes/>,
          },
          {
            label: "Dictamen final",
            element: <ReporteDictamenFinal/>,
          },
        ],
      },
      {
        label: "Vaciados Excel",
        onClick: () => handleVaciadosExcelClick(),
      },
      {
        label: "Generar Inconformidad",
        element: <p>Generar Inconformidad</p>,
      },
      {
        label: "Administración",
        element: <p>Contenido de la Administración</p>,
        children: [
          {
            label: "Comisiones",
            element: <p>Comisiones</p>,
          },
          {
            label: "Estadisticas",
            element: <p>Estadisticas</p>,
          },
          {
            label: "Modificar usuario",
            element: <p>Modificar usuario</p>,
          },
          {
            label: "Nuevo usuario",
            element: <p>Nuevo usuario</p>,
          },
          {
            label: "Incumplimiento de académico",
            element: <p>Incumplimiento de académico</p>,
          },
          {
            label: "Editar solicitud",
            element: <p>Editar solicitud</p>,
          },
          {
            label: "Activar / inactivar solicitud",
            element: <ActivarInactivarSolicitudComponent />,
          },
        ],
      },
      {
        label: "Publicaciones",
        element: <p>Publicaciones</p>,
      },
    ],
    {
      selectedValue: "Desempeño docente",
      isVertical: false,
    }
  );

  console.log("Token:", accessToken);
  console.log("User Info:", userInfo);
  return (
    <>
      <Header />
      <div className={styles.container}>
        <Menu menu={menu} />

        {menu.element}
      </div>

      <Footer />
    </>
  );
};
