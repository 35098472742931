import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "../features/layout";
import { HomePage } from "../features/homepage/pages/HomePage";
import { InstruccionesPage } from "../features/instrucciones";
import { InconformidadPage } from "../features/inconformidad";
import { JefeDepartamentoPage } from "../features/jefe_departamento";
import { AdministracionPage } from "../features/administracion/pages/AdministracionPage";
import { ReportesPage } from "../features/reportes";
import CommissionForm from "../features/miembros_comision/pages/CommisionForm.jsx";
import ReimprimirSolicitud from "../features/reimprimir-solicitud/pages/ReimprimirSolicitud.jsx";
import { ListaMiembros } from "../features/lista-miembros/pages/ListaMiembros.jsx";
import { LlenarSolicitudPage } from "../features/academico/llenarSolicitud/index.js";
import EvaluationPage from "../features/evaluarestudiante/pages/EvaluationPage.jsx";
import { VisorDocumentos } from "../features/visorDeDocumentos/pages/VisorDocumentos.jsx";


export const Navigation = () => {
  return (
    <>
      <Routes>
        <Route path="/homepage" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/visor-documentos" element={<VisorDocumentos />} />
        <Route path="/instrucciones" element={<InstruccionesPage />} />
        <Route path="/inconformidad" element={<InconformidadPage />} />
        <Route path="/jefe_departamento" element={<JefeDepartamentoPage />} />
        <Route path="/administracion" element={<AdministracionPage />} />
        <Route path="/llenar-solicitud" element={<LlenarSolicitudPage />} />
        <Route path="/reportes" element={<ReportesPage />} />
        <Route path="/actualizar-academico" element={<ListaMiembros />} />  
        <Route path="/miembros-comision" element={<CommissionForm />} />
        <Route path="/reimprimir-solicitud" element={<ReimprimirSolicitud />} />
        <Route path="/evaluacionpage" element={<EvaluationPage />} />
        {/* Ruta por defecto */}
        <Route path="*" element={<Navigate to="/homepage" replace />} />
      </Routes>
    </>
  );
};
