import React from "react";
import { Menu, useMenu } from "../../../reutilizable/Menu";
import { Footer, Header } from "../../layout";
import { Instrucciones } from "./Instrucciones";
import styles from "./visorDocumentos.module.css";
import { VisorDocumentos } from "./VisorDocumentos";
import CartaDesempeñoDocente from "../../jefe_departamento/components/CartaDesempeñoDocente";

export const VisorDocumentosComponent = () => {
  const menu = useMenu([
    {
      label: "Instrucciones",
      element: <Instrucciones />,
    },
    {
      label: "Visor de documentos",
      element: <VisorDocumentos />, 
    },
  ]);

  return (
    <>
      <Header />
      <div className={styles.container}>
        <Menu menu={menu} />
      
        {menu.element}
      </div>

      <Footer />
    </>
  );
};
