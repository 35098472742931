import React, { useState } from 'react';
import styles from './styles/ReporteDictamenNoParticipante.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faPrint, faSave } from '@fortawesome/free-solid-svg-icons';
import Search from '../../../reutilizable/Search';
import Select from '../../../reutilizable/Select';
import Table from '../../../reutilizable/Table';

const ReporteDictamenNoParticipantes = () => {
  const [bodyTable, _] = useState([
    {
      id: 1,
      num: 1,
      nombre: 'Reynoso Alvarez Alejandro',
      tipo: 'PRODEP',
      dependencia: 'cucsh',
      nivel: '-',
      calidad: 430,
      pts: 630
    },
    {
      id: 2,
      num: 1,
      nombre: 'Cabrera Gonzales Jose Luis',
      tipo: 'Evaluacion',
      dependencia: 'prepa7',
      nivel: '-',
      calidad: 430,
      pts: 630
    },
  ]);

  const CABECERAS_TABLA = [
    {
      id: 1,
      labelCabecera: 'input'
    },
    {
      id: 2,
      labelCabecera: '#'
    },
    {
      id: 3,
      labelCabecera: 'Apellido(S) Nombre(s)'
    },
    {
      id: 4,
      labelCabecera: 'Tipo'
    },
    {
      id: 5,
      labelCabecera: 'Dependencia'
    },
    {
      id: 6,
      labelCabecera: 'Nivel'
    },
    {
      id: 7,
      labelCabecera: 'Calidad'
    },
    {
      id: 8,
      labelCabecera: 'Pts.'
    },
  ];

  const handleSelectRow = (id) => {
    console.log('Seleccionando fila: ', id);
  };

  return (
    <div className={styles.container}>

      <p className={styles.title_page}>Dictamen de no participantes</p>

      <div className={styles.container_parrafo}>
        <p className={styles.p_instrucciones}>
          <FontAwesomeIcon icon={faAngleRight} color={'yellow'} /> Instrucciones
        </p>

        <p className={styles.parrafo_instrucciones}>
          Seleccione de la tabla los acádemicos para los cuales se vaya a generar el reporte. Elija los que desee
          usando las casillas <br/> correspondientes, o todos los de la tabla haciendo clic en la casilla de la cabecera. <br />
          También puede realizar búsquedas especificas por nombre, apellido, código, tipo de participación u otros
          datos del académico. <br /> Si se desea hacer búsquedas por nivel (I-IX), habrá que especificarlo de la siguiente
          forma: "n=I", o "n=II", etc. <br />
          Cuando haya terminado, haga clic en "Generar PDF <FontAwesomeIcon icon={faSave} color={'cyan'} />".
        </p>
      </div>

      <div className={styles.container_table}>
        <div className={styles.container_filtros_tabla}>
          <div>
            <p>Buscar:</p>
            <Search placeholder={'Teclee su búsqueda'}/> {/* Pendiente pasar las props de este componente */}
          </div>
          <p>en</p>
          <div>
            <Select/> {/* Pendiente pasar las props de este componente */}
          </div>
        </div>
        <Table cabecerasTable={CABECERAS_TABLA} bodyTable={bodyTable} withHeader={true}>
          {bodyTable.map(dataBody => (
              <tr key={dataBody.id}>
                <td>
                  <input 
                    type="checkbox"
                    className={styles.td_checkbox}
                    onClick={() => handleSelectRow(dataBody.id)}
                  />
                </td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.id}</td>
                <td className={`${styles.td} ${styles.td_nombre}`}>{dataBody.nombre}</td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.tipo}</td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.dependencia}</td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.nivel}</td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.calidad}</td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.pts}</td>
              </tr>
            ))}
        </Table>
      </div>

      <div className={styles.container_buttons}>
        <button
          type="button"
          placeholder='Limpiar'
        >
          Generar PDF <FontAwesomeIcon icon={faSave} color='cyan' />
        </button>
      </div>
    </div>
  )
}

export default ReporteDictamenNoParticipantes
