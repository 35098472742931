import styles from "./DatosParticipante.module.css";

export const DatosParticipante = () => {
  return (
    <>
      <div className={styles.container}>
        <div>
          <h3>Datos personales</h3>
          <p>Codigo: {`123213`}</p>
          <p>Nombre: {`JESUS PEREZ PEREZ`}</p>
          <p>RFC: {`PEPJ531109HDETC`}</p>
          <p>CURP: {`PEPJCURP09HDETC`}</p>
          <p>Último grado: {`Maestría`}</p>
          <p>Nombre de grado académico: {`Maestro`}</p>
          <p>Instituto Otorgante: {`Universidad de Guadalajara`}</p>
          <p>Correo electrónico: {`Correo@correo.com`}</p>
          <p>Telefono: {`5544332211`} ext. no</p>
          <p>Telefono móvil: {`0102030405`}</p>
          <p>País de nacimiento: {`México`}</p>
          <p>Estado de nacimiento: {`México, D.F.`}</p>
          <br />
          <p>Datos del nombramiento o contrato actual</p>
          <p>Nombre: {`Profesor Docente Asociado "A"`}</p>
          <p>Horas frente a grupo: {33}</p>
          <p>Dependencia: {`Escuela preparatoria No. 10`}</p>
          <p>Municipio: {`Zapopan`}</p>
          <p>Fecha de ingreso: {`16 de Febrero de 1994`}</p>
          <p>Antiguedad: {`29 años`}</p>
          <p>¿Puesto directivo?: {`No`}</p>
        </div>
        <div>Documentos recuperados de ALFRESCO</div>
      </div>
    </>
  );
};
