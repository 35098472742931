import React from "react";
import Select from "../../../reutilizable/Select";
import { useForm } from "react-hook-form";
import styles from "./CartaDesempeñoDocente.module.css";
import Search from "../../../reutilizable/Search";
import TablaMaterias from "./TablaMaterias";

const CartaDesempeñoDocente = () => {
  const options = [
    { value: 2021, label: "2021" },
    { value: 2022, label: "2022" },
    { value: 2023, label: "2023" },
    { value: 2024, label: "2024" },
  ];
  const form = useForm();

  return (
    <div>
      <span>
        <div className={styles.fields}>
          <span className={styles.field_container}>
            <span className={styles.field_label}>CICLO ESCOLAR:</span>
            <span className={styles.field_select}>
              <Select options={options} form={form} name="ciclo_escolar" />
            </span>
          </span>

          <span className={styles.field_container}>
            <span className={styles.field_label}>DOCENTE:</span>
            <Search
              onGetSuggestions={(query) => {
                return [
                  "Maria Gonzalez",
                  "Juan Perez",
                  "Pedro Perez",
                  "Alejandro Sebastian Lopez de la Cruz",
                ];
              }}
              onSearch={(query) => {
                console.log("Search", query);
              }}
            />
          </span>

          <span className={styles.field_container}>
            <span className={styles.field_label}>CÓDIGO DOCENTE:</span>
            <Search
              onGetSuggestions={(query) => {
                return ["123456", "654321", "111111", "222222"];
              }}
              onSearch={(query) => {
                console.log("Search", query);
              }}
            />
          </span>
        </div>

        <article>
          <header>
            <h3 className={styles.constancia_title}>
              Constancia de evaluación docente
            </h3>
          </header>

          {/* Pasamos la función de descarga como prop a la tabla */}
          <TablaMaterias />
        </article>
      </span>
    </div>
  );
};

export default CartaDesempeñoDocente;
