  import axios from 'axios';
  import { jwtDecode } from 'jwt-decode';
  import { useAuthStore } from '../store/useAuthStore'; 
  // Obtener la URL base desde las variables de entorno
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Concatenar el contexto y el servicio/recurso
  const API_URL = `${API_BASE_URL}/auth/login`;
  const API_REFRESH_URL = `${API_BASE_URL}/auth/refresh`;

  export const login = async (username, password) => {
    try {
      const response = await axios.post(API_URL, {
        username,
        pwd: password,
      });
  
      console.log("Login response:", response.data);
  
      if (response.data) {
        const { accessToken, refreshToken } = response.data;
  
        if (typeof accessToken === 'string' && accessToken.trim() !== '') {
          const decodedToken = jwtDecode(accessToken);
          const roles = decodedToken.roles || [];
          const rolesAsArray = Array.isArray(roles) ? roles : [roles];
  
          // Actualizamos Zustand con el token y la información del usuario
          const setAccessToken = useAuthStore.getState().setAccessToken;
          const setRefreshToken = useAuthStore.getState().setRefreshToken;
          const setUserInfo = useAuthStore.getState().setUserInfo;
  
          // Almacenar tokens en Zustand y en localStorage
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
          localStorage.setItem("accessToken", accessToken);  // <-- Asegúrate de almacenar el access token
          localStorage.setItem("refreshToken", refreshToken);  // <-- Almacena también el refresh token
  
          setUserInfo({
            roles: rolesAsArray,
            username: decodedToken.sub,
          });
  
          return { accessToken, refreshToken, roles, rolesAsArray };
        } else {
          throw new Error("Invalid token format received from the server");
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
      throw new Error('Login failed. Please check your credentials.');
    }
  };
  
  export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    try {
      const response = await axios.post(API_REFRESH_URL, { refreshToken });

      if (response.data) {
        const { accessToken } = response.data;
        localStorage.setItem('accessToken', accessToken);  // Actualizar el access token
        return accessToken;
      }
    } catch (error) {
      console.error('Error while refreshing access token:', error);
      throw new Error('Could not refresh access token');
    }
  };
  export const isTokenExpired = (token) => {
    if (!token) return true;
    
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;  // Tiempo actual en segundos

    return decodedToken.exp < currentTime;
  };


  export const getDecodedToken = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      return jwtDecode(accessToken);
    }
    return null;
  };
  export const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    console.log("Usuario desconectado");
  };  

  export const getAccessToken = async () => {
    let accessToken = localStorage.getItem('accessToken');

    if (!accessToken || isTokenExpired(accessToken)) {
      // El token ha expirado o no está presente, refrescarlo
      console.log("Access token expired. Refreshing...");
      accessToken = await refreshAccessToken();
    }

    return accessToken;
  };
  export const fetchUserProfile = async () => {
    try {
      const token = await getAccessToken();  // Obtener o renovar el access token
      const response = await axios.get('/user/profile', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  };


