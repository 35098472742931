import React from 'react';
import CheckboxComponent from './CheckboxComponent';
import styles from './EvaluationTableComponent.module.css';

const EvaluationTableComponent = ({ evaluations, toggleValidacion }) => {
  return (
    <table className={styles.evaluationTable}>
      <thead>
        <tr>
          <th>PROGRAMA EDUCATIVO</th>
          <th>CRN</th>
          <th>CLAVE</th>
          <th>ASIGNATURA</th>
          <th>CARGA HORARIA</th>
          <th>RESULTADO</th>
          <th>VALIDADA</th> {/* Nueva columna para validación */}
        </tr>
      </thead>
      <tbody>
        {evaluations.map((evaluation, index) => (
          <tr key={index}>
            <td>{evaluation.programa}</td>
            <td>{evaluation.crn}</td>
            <td>{evaluation.clave}</td>
            <td>{evaluation.asignatura}</td>
            <td>{evaluation.cargaHoraria}</td>
            <td>{evaluation.resultado}</td>
            <td>
              <CheckboxComponent
                checked={evaluation.validada}
                onChange={() => toggleValidacion(index)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EvaluationTableComponent;
