import React, { useState, useEffect } from "react";
import styles from "./llenarSolicitudPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";

export const LlenarSolicitudComponent = () => {
  const initialState = {
    codigo: "",
    CURP: "",
    entidad: "",
    nombre: "",
    correo: "",
    apellidoP: "",
    telefono: "",
    ext: "",
    apellidoM: "",
    telefonoMovil: "",
    rfc: "",
    nacionalidad: "",
    ultimogrado: "",
    areaconocimiento: "",
    nombregradoacademico: "",
    institutoegreso: "",
    nombramiento: "",
    departamento: "",
    horasfrenteagrupo: "",
    nombredirector: "",
    niveleducativo: "",
    dependencia: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(""); // Estado para manejar el mensaje de error

  // Manejar cambios en los inputs
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  // Validar campos
  const validateFields = () => {
    let hasError = false;
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        hasError = true;
      }
    });
    return hasError;
  };

  // Enviar formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    const hasError = validateFields();
    if (hasError) {
      setError("Todos los campos son obligatorios."); // Mensaje general de error
    } else {
      setError(""); // Limpiar el error si no hay problemas
      console.log("Formulario enviado con los siguientes datos:", formData);
      alert("Formulario enviado correctamente");
    }
  };

  // Limpiar formulario
  const handleCancel = () => {
    setFormData(initialState);
    setError("");
  };

  // Efecto para limpiar los errores después de 3 segundos
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(""); // Limpiar el mensaje de error después de 3 segundos
      }, 3000);
      return () => clearTimeout(timer); // Limpiar el timeout si el componente se desmonta
    }
  }, [error]);

  return (
    <>
      <div className={styles.solicitudContainer}>
        <h2 className={styles.titulo}>
          PROGRAMA DE ESTIMULOS AL DESEMPEÑO DOCENTE 2023-2024
        </h2>
        <p>
          Revise que su información sea correcta antes de suscribir la
          solicitud.
        </p>

        <div className={styles.container}>
          <form className={styles.form} id="datosPersonalesForm">
            <div className={styles.form_data}>
              <h2 className={styles.titulo}>Datos personales</h2>

              <div className={styles.inputContainer}>
                <label htmlFor="codigo">Código</label>
                <input
                  id="codigo"
                  type="text"
                  value={formData.codigo}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="nombre">Nombre</label>
                <input
                  id="nombre"
                  type="text"
                  value={formData.nombre}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="apellidoP">Apellido Paterno</label>
                <input
                  id="apellidoP"
                  type="text"
                  value={formData.apellidoP}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="apellidoM">Apellido Materno</label>
                <input
                  id="apellidoM"
                  type="text"
                  value={formData.apellidoM}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="rfc">RFC</label>
                <input
                  id="rfc"
                  type="text"
                  value={formData.rfc}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="CURP">CURP</label>
                <input
                  id="CURP"
                  type="text"
                  value={formData.CURP}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="ultimogrado">Último grado</label>
                <input
                  id="ultimogrado"
                  type="text"
                  value={formData.ultimogrado}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="areaconocimiento">Área de conocimiento</label>
                <input
                  id="areaconocimiento"
                  type="text"
                  value={formData.areaconocimiento}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="nombregradoacademico">
                  Nombre del grado académico
                </label>
                <input
                  id="nombregradoacademico"
                  type="text"
                  value={formData.nombregradoacademico}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="institutoegreso">Institución otorgante</label>
                <input
                  id="institutoegreso"
                  type="text"
                  value={formData.institutoegreso}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="correo">Correo Electrónico</label>
                <input
                  id="correo"
                  type="email"
                  value={formData.correo}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="telefono">Teléfono</label>
                <input
                  id="telefono"
                  type="tel"
                  value={formData.telefono}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="ext">Ext.</label>
                <input
                  id="ext"
                  type="number"
                  value={formData.ext}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="telefonoMovil">Teléfono Móvil</label>
                <input
                  id="telefonoMovil"
                  type="tel"
                  value={formData.telefonoMovil}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="nacionalidad">Nacionalidad</label>
                <input
                  id="nacionalidad"
                  type="text"
                  value={formData.nacionalidad}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="entidad">Entidad Federativa</label>
                <input
                  id="entidad"
                  type="text"
                  value={formData.entidad}
                  onChange={handleChange}
                />
              </div>
            </div>
          </form>

          <h2 className={styles.titulo}>
            Datos de nombramiento o contrato actual
          </h2>
          <form className={styles.form} id="informacionAcademicaForm">
            <div className={styles.form_data}>
              <div className={styles.inputContainer}>
                <label htmlFor="nombramiento">Nombramiento</label>
                <select
                  id="nombramiento"
                  value={formData.nombramiento}
                  onChange={handleChange}
                >
                  <option value="">Seleccione</option>
                  <option value="profesor">Profesor</option>
                  <option value="investigador">Investigador</option>
                </select>
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="horasfrenteagrupo">Horas frente a grupo</label>
                <input
                  id="horasfrenteagrupo"
                  type="text"
                  value={formData.horasfrenteagrupo}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="niveleducativo">Nivel educativo</label>
                <select
                  id="niveleducativo"
                  value={formData.niveleducativo}
                  onChange={handleChange}
                >
                  <option value="">Seleccione</option>
                  <option value="licenciatura">Licenciatura</option>
                  <option value="maestria">Maestría</option>
                </select>
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="dependencia">Dependencia</label>
                <select
                  id="dependencia"
                  value={formData.dependencia}
                  onChange={handleChange}
                >
                  <option value="">Seleccione</option>
                  <option value="universidad">Universidad</option>
                  <option value="instituto">Instituto</option>
                </select>
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="departamento">Departamento</label>
                <input
                  id="departamento"
                  type="text"
                  value={formData.departamento}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="nombredirector">
                  Nombre del jefe de departamento
                </label>
                <input
                  id="nombredirector"
                  type="text"
                  value={formData.nombredirector}
                  onChange={handleChange}
                />
              </div>
            </div>
          </form>

          {error && (
            <p className={styles.error}>{error}</p> // Mostrar mensaje de error general
          )}

          <div className={styles.submit}>
            <button
              className={`${styles.btn} ${styles.enviar}`}
              type="submit"
              onClick={handleSubmit}
            >
              Guardar
              <span className={styles.iconContainer}>
                <FontAwesomeIcon
                  icon={faFloppyDisk}
                  className={styles.customIcon}
                />
              </span>
            </button>
            <button
              className={`${styles.btn} ${styles.cancelar}`}
              type="button"
              onClick={handleCancel}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
