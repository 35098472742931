import React from 'react';
import styles from './EvaluationFiltersComponent.module.css';
import Search from "../../../reutilizable/Search";

const EvaluationFiltersComponent = ({ onSearch }) => {
  return (
    <div>
      <div className={styles.filters}>
        {/* Nombre del Profesor Search */}
        <div className={styles.filterItem}>
          <label>NOMBRE DEL PROFESOR:</label>
          <Search
            onGetSuggestions={(query) => {
              return [
                "Maria Gonzalez",
                "Juan Perez",
                "Pedro Perez",
                "Alejandro Sebastian Lopez de la Cruz",
              ];
            }}
            onSearch={(query) => {
              console.log("Search profesor:", query);
            }}
          />
        </div>

        {/* Tipo de Nombramiento Search */}
        <div className={styles.filterItem}>
          <label>TIPO DE NOMBREAMIENTO:</label>
          <Search
            onGetSuggestions={(query) => {
              return [
                "Tiempo Completo",
                "Medio Tiempo",
                "Horas Clases",
                "Investigador",
              ];
            }}
            onSearch={(query) => {
              console.log("Search nombramiento:", query);
            }}
          />
        </div>

        {/* Código del Profesor Search */}
        <div className={styles.filterItem}>
          <label>CÓDIGO DEL PROFESOR:</label>
          <Search
            onGetSuggestions={(query) => {
              return ["123456", "654321", "111111", "222222"];
            }}
            onSearch={(query) => {
              console.log("Search código profesor:", query);
            }}
          />
        </div>

        {/* Ciclo Escolar Select */}
        <div className={styles.filterItem}>
          <label>CICLO ESCOLAR:</label>
          <select>
            <option value="">Seleccione un ciclo escolar</option>
            <option value="2024A">2024A</option>
            <option value="2024B">2024B</option>
          </select>
        </div>

        {/* Botón de Búsqueda */}
        <button onClick={onSearch} className={styles.searchButton}>
          <i className="fa fa-search"></i> Buscar
        </button>
      </div>

      {/* Colocamos el título fuera del div que contiene los filtros */}
      <article>
        <header className={styles.constancia_title_container}>
          <h3 className={styles.constancia_title}>
            Constancia de evaluación de estudiante
          </h3>
        </header>
      </article>
    </div>
  );
};

export default EvaluationFiltersComponent;
