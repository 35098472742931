import React, { useState, useEffect } from "react";
import { login } from "../../../../features/authService"; // Import the login function
import styles from "./login.styles.module.css";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router

export const LoginComponent = () => {
  const [usuario, setUsuario] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate to handle redirection

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!usuario || !fechaNacimiento) {
      setError("Todos los campos son obligatorios.");
    } else {
      try {
        // Call the login function and get roles and permissions
        const { roles, rolesAsArray } = await login(usuario, fechaNacimiento);
        setError("");

        // Redirección basada en los roles del usuario
        if (rolesAsArray.includes("jefe_depto")) {
          navigate("/jefe_departamento"); // Redirigir a la página de jefe de departamento
        } else if (rolesAsArray.includes("admin_gral")) {
          navigate("/administracion"); // Redirigir a la página de administración general
        } else if (rolesAsArray.includes("admin_convocatoria")) {
          navigate("/administracion");
        } else if (rolesAsArray.includes("Academico")) {
          navigate("/academico-dashboard"); // Redirigir a la página de académico
        } else {
          navigate("/default-dashboard"); // Redirigir a una página por defecto si no hay roles específicos
        }
      } catch (error) {
        setError("Login failed. Please check your credentials.");
      }
    }
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(""); // Clear the error after 3 seconds
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div>
      <div className={styles.main}>
        <p className={styles.text}>
          Bienvenido al Programa de
          <br />
          <span className={styles.fontxl}>
            Estímulos al Desempeño Docente 2024-2023
          </span>
        </p>

        <div className={styles.login_container_head}>
          <span className={styles.textlogin}>
            Para ingresar al sistema por favor proporcione su usuario y
            contraseña
          </span>
        </div>

        <div className={styles.login_container}>
          <form onSubmit={handleSubmit}>
            <div className={styles["form-group"]}>
              <label htmlFor="usuario" className={styles.label}>
                USUARIO / CODIGO
              </label>
              <div className={styles.formplace}>
                <input
                  id="usuario"
                  type="text"
                  className={styles.input}
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                />
                <span className={styles.placeholder}>(Su código, si es académico)</span>
              </div>
            </div>
            <div className={styles["form-group"]}>
              <label htmlFor="fecha_nacimiento" className={styles.label}>
                FECHA DE NACIMIENTO
              </label>
              <div className={styles.formplace}>
              <input
                id="fecha_nacimiento"
                type="text"
                className={styles.input}
                value={fechaNacimiento}
                onChange={(e) => setFechaNacimiento(e.target.value)}
                placeholder="" 
                
              />
              <span className={styles.placeholder}>(DD-MM-AAAA)</span>
              </div>
            </div>
            <div className={styles["button-container"]}>
              <button className={styles.button}>Ingresar</button>
            </div>

            {error && <p className={styles.error}>{error}</p>}

          </form>
        </div>
        {/* Sección adicional para links e información */}
        <div className={styles.infoSection}>
          <p className={styles.infoText}>
            Si desea consultar la convocatoria, tabla de puntaje y reglamento del PROESDE,<br></br>
            puede hacerlo mediante el portal de la CGAI:
          </p>
          <button className={styles.infoButton}>
            Coordinación General Académica y de Innovación
          </button>
          </div>

          <div className={styles.contactSection}>
            <p className={styles.contactInfo}>
              Si desea obtener mayor información acerca de la convocatoria contacte al<br></br>
              teléfono <strong>333134-2200 extensión 11966</strong> en un horario de <strong>10:00 a 18:00 hrs</strong>.
            </p>

            <p className={styles.contactInfo}>
              En caso de tener algún error en el sistema contacte por medio del <br></br>
              correo electrónico a <a href="mailto:proesde@udg.mx">proesde@udg.mx</a>.
            </p>
          </div>
        

        {/* Sección de compatibilidad de navegador */}
        <div className={styles.browserCompatibility}>
          <p>NO soporta Internet Explorer, Microsoft Edge y Safari.</p>
          <p>Este sitio se visualiza mejor con Firefox o Google Chrome</p>
        </div>
      </div>
    </div>
  );
};